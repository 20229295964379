* {
	box-sizing: border-box;
	overscroll-behavior: none;
}

a {
	text-decoration: none;
}

html {
	-webkit-tap-highlight-color: transparent;
}

html,
body,
#root,
.App {
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
