.home {
	background: url("../media/bg.jpg") top center no-repeat;
	background-size: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
	padding-top: 50px;
}

.contents {
	width: 600px;
	max-width: 100%;
	padding: 5%;
}

.title {
	color: #fff;
	margin: 0;
	font-size: 30px;
	text-align: center;
}

.search {
	width: 100%;
	margin-top: 30px;
	position: relative;
}

.search__input {
	width: 100%;
	height: 50px;
	border-radius: 5px;
	border: 1px solid #eee;
	font-size: 16px;
	padding: 10px 60px 10px 20px;
}

.search__button {
	width: 50px;
	height: 50px;
	padding: 0;
	position: absolute;
	top: 0;
	right: 0;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-left: 1px solid #eee;
	cursor: pointer;
}

.search__button__img {
	width: 30px;
	height: 30px;
}

@media screen and (max-width: 600px) {
	.home {
		background-size: 200%;
	}
}
