.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header:global(.innerPage) {
	background-color: #008be8;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.logo {
	height: 40px;
	display: inline-block;
	vertical-align: top;
}
