.page {
	padding-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #333;
}

.contents {
	width: 800px;
	max-width: 100%;
	padding: 5%;
}

.title {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
}

.grid {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	margin-top: 30px;
}

.more {
	text-align: center;
	margin-top: 20px;
}

.ad {
	background-color: #fff;
	border: 1px solid #eee;
	border-bottom: 4px solid #008be8;
	position: relative;
}

.ad__imageWrapper {
	width: 100%;
	height: 0;
	padding-top: 50%;
	background-size: cover;
	background-position: top center;
}

.ad__overlay {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.54);
	color: #fff;
	padding: 5px 15px;
	font-size: 13px;
}

.ad__details {
	padding: 15px;
}

.ad__price {
	font-size: 20px;
	font-weight: bold;
}

.ad__description {
	font-size: 14px;
	line-height: 16px;
	margin-top: 5px;
}

.ad__address {
	font-size: 12px;
	line-height: 14px;
	margin-top: 5px;
}

@media screen and (max-width: 600px) {
	.contents {
		padding: 30px 20px;
	}

	.grid {
		grid-template-columns: 1fr;
	}
}
